<template>
  <a-card style="height: 800px;width: 100%;overflow:hidden" class="_page-content">
    <div class="department-outer">
      <div class="zoom-box">
        <zoom-controller v-model="zoom" :min="20" :max="200"></zoom-controller>
      </div>
      <div class="view-box">
        <org-view
          v-if="data"
          :data="data"
          :zoom-handled="zoomHandled"
          @on-menu-click="handleMenuClick"
        ></org-view>
      </div>
      <a-modal v-model="isShowDetail" destroyOnClose title="查看详情" onOk="handleOk" width="800px" height="500px">
        <template slot="footer">
          <a-button key="back" type="primary" @click="handleCancel">关闭</a-button>
        </template>
        <ax-form ref="mainReport" :formBuilder="formBuilder" >
        </ax-form>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import OrgView from '../../components/org-tree/org-view.vue'
import ZoomController from '../../components/org-tree/zoom-controller.vue'
import '../../components/org-tree/index.less'
import api from '../api'
export default {
  name: 'org_tree_page',
  components: {
    OrgView,
    ZoomController
  },
  data () {
    return {
      isShowDetail: false,
      report: {},
      data: {},
      zoom: 100,
      formBuilder: this.$common.initGridFormData([
        {
          type: 'input',
          model: 'orgCode',
          rules: [{ required: true, message: '请输入用户名' }],
          colLabel: '机构代码:',
          rowCol: 12
        },
        {
          colLabel: '机构名称:',
          colName: 'departName',
          rowCol: 12
        },
        {
          colLabel: '部门层级:',
          colName: 'deptLevel_dictText',
          rowCol: 12
        },
        {
          colLabel: '部门属性:',
          colName: 'deptClass_dictText',
          rowCol: 12
        },
        {
          colName: 'queryBtn',
          colType: 'slot',
          rowCol: 24
        }
      ])
    }
  },
  computed: {
    zoomHandled () {
      return this.zoom / 100
    }
  },
  methods: {
    setDepartmentData (data) {
      data.isRoot = true
      return data
    },
    handleMenuClick ({ data, key }) {
      if (key === 'detail') {
        this.isShowDetail = true
        setTimeout(() => {
          api.getDeptId(data.id).then(res => {
            if (res.status === 200) {
              this.report = res.data
            } else {
              this.$message.error('操作失败')
            }
          })
        }, 100)
      }
    },
    // 会议安排取消按钮
    handleCancel (e) {
      this.isShowDetail = false
    }
  },
  mounted () {
    api.orgTree().then(res => {
      this.data = res.data
    })
  }
}
</script>
